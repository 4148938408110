import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { UserlessHeaderComponent } from '../shared/components/userless-header/userless-header.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [RouterOutlet,FooterComponent,UserlessHeaderComponent],
})
  
export class LoginComponent implements OnInit, OnChanges {
  url: string;
  state: string = 'default';
  constructor(
    private router: Router
  ) {


  }

  ngOnInit() {

  }
  ngOnChanges() {
    this.url = this.router.url;
  }
}