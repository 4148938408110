<ngx-ui-loader *ngIf="useFullLoader"
  pbColor="purple"
  overlayColor="rgba(255, 255, 255, 1)"
  fgsSize="0"
></ngx-ui-loader>

<ngx-ui-loader *ngIf="!useFullLoader"
  pbColor="purple"
  overlayColor="rgba(255, 255, 255, 0)"
  fgsSize="0"
></ngx-ui-loader>

<router-outlet></router-outlet>
