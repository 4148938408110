<!--/***************************************
  * Component: Error message component 
  * Created by: Ajay C
  * Updated by: Ajay C
  * Created on: 29/07/2019
******************************************/-->

<div class="container">
  <div class="error-image">
      <img src="{{images.pageNotFound}}" loading="lazy" alt="...">
  </div>
  <div class="message">
    <!-- <span>Internal Server Error</span> -->
  </div>
  <div class="load-again-button">
    <button mat-raised-button color="primary" (click)="redirectToHome()">Reload</button>
  </div>
</div>
