import { Injectable } from '@angular/core';
import { getMessaging, Messaging, onMessage, getToken } from '@angular/fire/messaging';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../cep/store/index';

@Injectable()
export class MessagingService {
  private messaging: Messaging;
  currentMessage = new BehaviorSubject(null);
  currentMessage$ = this.currentMessage.asObservable();
  message$: Observable<unknown>;

  constructor(
    private store: Store<fromStore.CEPState>
  ) {
    navigator.serviceWorker.register("/assets/firebase-messaging-sw.js", {
        type: "module",
      }).then((serviceWorkerRegistration) => {
        getToken(this.messaging, {
          vapidKey:'BPhKWb4qDldTmXe0MscuRHAZ-tZWskQAhT1U73_lGGcio55BpjJ_-X7rtul1GencfL9tllC-eVltdtEfSwm0uho',
          serviceWorkerRegistration: serviceWorkerRegistration,
        }).then((x) => {
          console.log('my fcm token', x);
          // This is a good place to then store it on your database for each user
        });
	  });  

    this.message$ = new Observable((sub) => onMessage(this.messaging, (msg) => sub.next(msg))).pipe(
	    tap((msg) => {
	      console.log("My Firebase Cloud Message", msg);
	    })
    );
    
 
  
    // Initialize the Firebase Messaging instance
    // this.messaging = getMessaging();
    // console.log(this.messaging)

    // Subscribe to foreground messages
    // onMessage(this.messaging, (payload) => {
    //   console.log('New message received: ', payload);
    //   this.currentMessage.next(payload);
    // });
  }

  requestPermission() {
    getToken(this.messaging, { vapidKey: 'BPhKWb4qDldTmXe0MscuRHAZ-tZWskQAhT1U73_lGGcio55BpjJ_-X7rtul1GencfL9tllC-eVltdtEfSwm0uho' })
      .then((token) => {
        if (token) {
          console.log('FCM Token:', token);
          this.store.dispatch(new fromStore.FcmToken({ token }));
        } else {
          console.error('No registration token available.');
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token.', err);
      });
  }

  receiveMessage() {
    onMessage(this.messaging, (payload) => {
      console.log('New message received in foreground:', payload);
      this.currentMessage.next(payload);
    });
  }

  handleNotificationClick(url: string) {
    window.open(url);
    this.logNotificationClick(url);
  }

  logNotificationClick(url: string) {
    console.log(`Notification clicked: ${url}`);
    // Example API call using HttpClient
    // this.http.post('/api/logNotificationClick', { url }).subscribe();
  }
}
