
import { importProvidersFrom, ApplicationConfig } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { provideAnimations,BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncPipe, CommonModule, UpperCasePipe } from '@angular/common';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, RouterModule, withInMemoryScrolling } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { routes } from './app-routes';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';


import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { NgOtpInputModule } from 'ng-otp-input';

import { effects } from './cep/store';
import { reducers, metaReducers } from './cep/store/reducers.index';
import { MessagingService } from './shared/services/messaging-service';



const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};
  
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

const ngxUiLoderConfig: NgxUiLoaderConfig = {
    bgsColor: 'rgba(12,80,219,0.98)',
    bgsOpacity: 1,
    bgsSize: 40,
    bgsType: "ball-spin-clockwise",
    fgsColor: 'rgba(12,80,219,0.98)',
    fgsPosition: "center-center"
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes, inMemoryScrollingFeature),
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            RouterModule,
            CommonModule,
            UpperCasePipe,
            ReactiveFormsModule,
            FormsModule,
            SharedModule,
            NgOtpInputModule,
            StoreModule.forFeature('cep', reducers),
            EffectsModule.forRoot(effects),
            StoreModule.forRoot(reducers, {
                metaReducers,
                runtimeChecks: {
                    // strictStateImmutability: true,
                    // strictActionImmutability: true
                }
            }),
            StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
            NgbModule,
            NgxUiLoaderModule.forRoot(ngxUiLoderConfig),
            NgxUiLoaderHttpModule.forRoot({
                showForeground: true,
                exclude: [
                    environment.base_url + "/user/v1/GroupCtrl/getGroupDetails",
                    environment.base_url + "/user/v1/MessageCtrl/createMessage",
                    environment.base_url + "/user/v1/GroupCtrl/getAllUserGroups",
                    environment.base_url + "/authenticationConfiguration/v1/checkAuthUser"
                ],
            }),
        ),

        provideFirebaseApp(() => initializeApp({
            apiKey: "AIzaSyBTCZ2voUftJA4u7pfnFhWddHhAIMBDuoE",
            authDomain: "pmc-test-1a102.firebaseapp.com",
            projectId: "pmc-test-1a102",
            storageBucket: "pmc-test-1a102.firebasestorage.app",
            messagingSenderId: "907012612821",
            appId: "1:907012612821:web:d540b724c67b5cf859f85c"
        })),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideMessaging(() => getMessaging()),
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        MessagingService,
        AsyncPipe,
    ]
}