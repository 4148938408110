<div class="container"  id="canvas_div_pdf">
  <div class="row print_chs_card" style="background: #dae7df;border: 1px solid #dae7df;border-radius: 5px;">

      <div class="col-12 col-md-12" style="display: inline-flex;text-align: center;width: 100%;">
          <div class="col-md-9" style="padding: 10px;margin: auto auto;text-align: center;">
              <h1>पुणे महानगरपालिका</h1>
              <h3 style="text-align: center;">शहरी गरीब योजना कार्ड</h3>
          </div>
          <div class="col-md-3" style="padding: 10px;margin: auto auto;text-align: left;">
              <img src="https://sgy.pmc.gov.in/Modules/Admin/Resources/assets/images/pmc-logo-new-1.png" alt="" style="width: 100px; height: 100px;">
          </div>
      </div>
      <div class="col-md-12" style="padding: 2px;align-items: center;justify-content: center;">
          <p class="text-center" style="text-align: center;font-weight: bold;">पुणे महानगरपालिकेच्या कार्यक्षेत्रातील झोपडपट्ट्यांमध्ये राहणाऱ्या व दारिद्र्यरेषेखालील पिवळे रेशनकार्ड धारक व कुटुंबाचे वार्षिक उत्पन्न रु. १ लाखापर्यंत असणाऱ्या गरीब कुंटुबाना</p>
      </div>
      <div class="col-12 col-md-12" style="display: flex;background: #dae7df;">
          <div class="col-xs-6" style="padding: 10px;width: 50%;margin: auto auto;align-items: center;justify-content: center;text-align: left;">
              <p class="text-center">कार्ड क्रमांक : <strong>{{ cardDetails.uid }}</strong></p>
              <p class="text-center mb-0" style="line-height: 1;">सभासदाचे नाव : <strong> {{ cardDetails.name }} </strong></p>
              <p class="text-center  mb-0" style="line-height: 1;">राहण्याचा पत्ता: <strong> {{ cardDetails.address }}</strong></p>
              <p class="text-center  mb-0" style="line-height: 1;">या कार्डाची वैद्यता दि : <strong> {{ expiryDate }} </strong> पर्यंत राहील</p>
              <p class="text-center  mb-0" style="line-height: 1;">मोबाईल नंबर : <strong> {{ cardDetails.mobile }} </strong></p>
              <p class="text-center  mb-0" style="line-height: 1;text-align: left;">सभासदत्व दि. :    <strong> {{ registerDate }} </strong></p>
          </div>
          <div class="col-xs-6" style="padding: 10px;width: 50%;margin: auto auto;align-items: center;justify-content: center;text-align: left;">
              <div class="text-center" style="margin: 2%;    text-align: center;">
                  <img src="{{ familyPreview + cardDetails.familyPhoto }}" alt="family photo" class="image-preview">
              </div>
          </div>
      </div>
      <div class="col-md-12" style="padding: 10px;align-items: center;justify-content: center;margin-top: 10px;">
          <table class="table table-bordered" width="100%" style="border-collapse: collapse;margin:auto;background: #dae7df;">
              <thead>
                  <tr>
                      <th style="padding: 5px; text-align: center;border: 1px solid #000000;  border-collapse: collapse;">क्र</th>
                      <th style="padding: 5px;text-align: center;border: 1px solid #000000;  border-collapse: collapse;">सभासदाचे नाव</th>
                      <th style="padding: 5px;text-align: center;border: 1px solid #000000;  border-collapse: collapse;"> वय</th>
                      <th style="padding: 5px;text-align: center;border: 1px solid #000000;  border-collapse: collapse;"> नाते</th>
                      <th style="padding: 5px;text-align: center;border: 1px solid #000000;  border-collapse: collapse;"> आधार क्रमांक</th>
                  </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="cardDetails && cardDetails.familyMember && cardDetails.familyMember.length > 0">
                    <tr *ngFor="let item of cardDetails.familyMember; let index = index">
                        <td style="padding: 5px;text-align: center;border: 1px solid #000000; border-collapse: collapse;"> {{ index + 1 }} </td>
                        <td style="padding: 2px 5px;text-align: left;border: 1px solid #000000; border-collapse: collapse;"> {{ item.full_name }} </td>
                        <td style="padding: 5px;text-align: center;border: 1px solid #000000; border-collapse: collapse;">{{ item.age }}</td>
                        <td style="padding: 5px;text-align: center;border: 1px solid #000000; border-collapse: collapse;">
                            {{ item.family_relation }}
                        </td>
                        <td style="padding: 5px;text-align: center;border: 1px solid #000000; border-collapse: collapse;">{{ item.adhar_card_no }}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="!cardDetails || !cardDetails.familyMember || cardDetails.familyMember.length === 0">
                    <tr>
                        <td colspan="5" style="text-align: center;">No family members found.</td>
                    </tr>
                </ng-container>
                
              </tbody>
          </table>
      </div>
      <div class="col-md-12" style="display: inline-flex;width: 100%;text-align: center;justify-content: center;margin-top: 10%;">
          <div class="col-md-11" style="text-align: center;margin:auto;">
              <p class="text-center  mb-0" style="line-height: 1;margin-top: 0;text-align: center;">वैद्यकीय अधिकारी</p>
              <div style="border-bottom:1px solid grey;width:'10%';margin: 0 auto;text-align: center;"></div>
              <p class="text-center  mb-0" style="padding-top:5px;line-height: 1;margin-top: 0;margin-bottom: 0;text-align: center;">दवाखाना/रुग्णालय/क्षेत्रीय कार्यालय</p>
              <p class="text-center  mb-0" style="line-height: 1;margin-top: 0;text-align: center;"> पुणे महानगरपालिका</p>
          </div>
      </div>
      <div class="col-md-12" style="top: 30px;align-items: center;justify-content: center;">
          <br/>
          <br/><br/>
          <p class="text-center" style="text-align: center;font-weight: bold;">Hospital visits details of SGY Card Members</p>
            <table class="table table-bordered" width="100%" style="border-collapse: collapse;background: #dae7df;">
                <thead>
                    <tr>
                        <th style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;">क्र </th>
                        <th style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;">दिनांक </th>
                        <th style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;"> हॉस्पिटलचे नाव </th>
                        <th style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;"> कार्ड सदस्याचे नाव </th>
                        <th style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;">वापरलेली रक्कम </th>
                    </tr>
                </thead>
                <tbody *ngIf="cardUsageHistory.length > 0">
                        <tr *ngFor="let usage of cardUsageHistory; let i = index">
                            <td style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;height:20px;">{{ i + 1 }}</td>
                            <td style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;height:20px;">{{ usage.created_at }}</td>
                            <td style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;height:20px;">{{ usage.hospital_name }}</td>
                            <td style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;height:20px;">{{ usage.patient_name }}</td>
                            <td style="padding: 5px;border: 1px solid #000000;  border-collapse: collapse;height:20px;"><span>₹ </span> {{ usage.used_amount }}</td>
                        </tr>
                </tbody>
            </table>
      </div>
      <div class="col-md-12" style="display: inline-flex;width: 100%;text-align: center;justify-content: center;margin-top: 10%;">
          <div class="col-md-11" style="text-align: center;margin:auto;">
              <p class="text-center  mb-0" style="line-height: 1;margin-top: 0;text-align: center;">
              </p>
              <p class="text-center  mb-0" style="line-height: 1;margin-top: 0;text-align: center;">वैद्यकीय अधिकारी</p>
              <div style="border-bottom:1px solid grey;width:'10%';margin: 0 auto;text-align: center;"></div>
              <p class="text-center  mb-0" style="padding-top:5px;line-height: 1;margin-top: 0;margin-bottom: 0;text-align: center;">दवाखाना/रुग्णालय/क्षेत्रीय कार्यालय</p>
              <p class="text-center  mb-0" style="line-height: 1;margin-top: 0;text-align: center;"> पुणे महानगरपालिका</p>
          </div>
      </div>
  </div>
</div>
