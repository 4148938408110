  import {
    Component,
    HostListener,
    Inject,
    AfterViewInit,
    OnDestroy,
    CUSTOM_ELEMENTS_SCHEMA,
    inject,
  } from "@angular/core";
  import { Store } from "@ngrx/store";
  import * as fromStore from "./cep/store/index";
  import { LocalStorageService } from "./shared/services/localstorage.service";
  import { EncrDecrService } from "./shared/services/encr-decr.service";
  import { ChatbotService } from "./shared/services/chatbotService.service";
  import { Firestore } from '@angular/fire/firestore';
  // import { Subscription } from 'rxjs';
  import {
    NavigationStart,
    Router,
    ActivatedRoute,
    NavigationEnd,
    RouterOutlet,
  } from "@angular/router";
  import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
  } from "@angular/material/dialog";
  import { IntroductionPopupComponent } from "./shared/components/introduction-popup/introduction-popup.component";
  import { environment } from "src/environments/environment";
  import { DeviceDetectorService } from "ngx-device-detector";
  import { AuthService } from "./auth.service";
  import { MessagingService } from "./shared/services/messaging-service";
import { CommonModule } from "@angular/common";

  // import { HttpClient } from '@angular/common/http';
  // import {ChatbotApi} from '../assets/chatbot';
  // declare function chatbotAPI(userId): any;
  // export let browserRefresh = false;
  //declare gives Angular app access to ga function
  declare let gtag: Function;

  @Component({
    selector: 'app-root',
    standalone:true,
    imports:[RouterOutlet,CommonModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
  })
  export class AppComponent implements OnDestroy {
    firestore = inject(Firestore);
    // subscription: Subscription;
    title = "CEP";
    public message;
    token: string;
    userId: any;
    chatbotUserId: string;
    url: any;
    body: {};
    chatBotUrl: string;
    headerContent: any;
    chatbotKey: ArrayBuffer;
    apiDetails: {};
    selectedLanguage: string;
    pathNameForEmail: string;
    isMobile: any;
    useFullLoader: true | false;

    // Use this list of pages to hotlist pages, that do not need a full page loader
    hotListPages = ["/Login/login"];

    constructor(
      private router: Router,
      private store: Store<fromStore.CEPState>,
      private localStorageService: LocalStorageService,
      private encrDecr: EncrDecrService,
      private route: ActivatedRoute,
      private chatbotService: ChatbotService,
      public dialog: MatDialog,
      private deviceService: DeviceDetectorService,
      private authService: AuthService,
      private messagingService:MessagingService
    ) {
      let token = this.localStorageService.getToken();
      let userId = this.localStorageService.getUserId();
      this.isMobile = this.deviceService.isMobile();

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag("config", "UA-145935166-1", {
            page_path: event.urlAfterRedirects,
          });
        }

        // We can use this variable to not use the foll blank loader on certain pages
        if (!this.hotListPages.includes(window.location.pathname)) {
          this.useFullLoader = false;
        } else {
          this.useFullLoader = true;
        }
      });

      // this.subscription = router.events.subscribe((event) => {
      //   if (event instanceof NavigationStart) {
      //     //this.dialog.open(IntroductionPopupComponent);
      //     browserRefresh = !router.navigated;
      //   }
      // });

      this.store.select(fromStore.getToken).subscribe((token) => {
        this.token = token;
        if (this.token) {
          let localUserId = this.localStorageService.getUserId();
          if (localUserId) { 
            this.chatbotUserId = this.encrDecr.get(localUserId);
          }
        }
      });

      if (token && userId) {
        this.store.dispatch(
          new fromStore.AddTokenUserId({
            token: token,
            userId: userId,
          })
        );
        if (window.location.pathname == "/") this.router.navigate(["/cep/feeds"]);
      }

      // else if (!token && !userId) {
      // 	// this.route.paramMap.subscribe( params => {
      // 		this.pathNameForEmail = window.location.pathname
      // 		if(this.pathNameForEmail.substring(0,31) == "/Login/forgot-password-by-email"){
      // 			this.router.navigate([window.location.pathname]);
      // 		}
      // 	// });
      // 	// if(window.location.pathname == '/login/forgot-password-by-email/'){

      // 	// }
      this.store.dispatch(new fromStore.GetApiRequestList({}));

      // this.messagingService.requestPermission();
      // this.messagingService.receiveMessage();
      
      // this.messagingService.currentMessage$.subscribe((message) => {
      //   this.message = message;
      //   console.log('New message received from App Component: ', message);
      // });
      
    }
  
    /**
     * Created by Akshata C
     * For opening introduction popup on page load
     */

    ngOnDestroy() {}
    
  }
