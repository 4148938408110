<div class="inputCard">
    <label>Blog Content</label>
    <angular-editor id="editor1" [(ngModel)]="content">
    </angular-editor>
</div><br>

<div>
    <button (click)="saveConetntToLocalStorage()">
        SAVE
    </button>
</div>
