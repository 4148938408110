
import { Routes } from '@angular/router';

import { CepComponent } from './cep/cep.component';
import { NoApiResponseComponent } from './shared/error-components/no-api-response/no-api-response.component';
import { LoginComponent } from './login/login.component';
import { EditorComponent } from './shared/components/editor/editor.component';
import { OneClickRegistrationComponent } from './cep/login-management/one-click-registration/one-click-registration.component';
import { OcrVerifyOtpComponent } from './cep/login-management/ocr-verify-otp/ocr-verify-otp.component';
import { SuccessDialogComponent } from './cep/login-management/success-dialog/success-dialog.component';
import { DownloadFormComponent } from './cep/side-menu-bar/SGY/download-form/download-form.component';




export const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'cep'  },
	{ path: 'cep', component:CepComponent, loadChildren: () => import('./cep/cep.module').then(m => m.CepModule) },
	{ path: 'Login', component:LoginComponent, loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
	{ path: '404/no-page-found', component: NoApiResponseComponent },
	{ path: 'admin-editor/:type', component: EditorComponent },
	{ path: 'one-click-registration',component:OneClickRegistrationComponent },
	{ path: 'ocr',component:OcrVerifyOtpComponent },
	{ path: 'success-dialog',component:SuccessDialogComponent },
	{ path: 'sgy-download',component:DownloadFormComponent }
];  


// @NgModule({
//     imports: [
//         RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' }),
//         CepComponent,
//         LoginComponent,
//         EditorComponent,
//         NoApiResponseComponent,
//     ],
//     exports: [RouterModule],
//     schemas: [CUSTOM_ELEMENTS_SCHEMA],
// })
// export class AppRoutingModule { }
